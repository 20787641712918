export enum AvatarStyle {
  Shapes = "shapes",
  Lorelei = "lorelei",
  Adventurer = "adventurer",
  Avataaars = "avataaars",
}

export function getAvatarUrl(seed?: string, type = AvatarStyle.Lorelei) {
  const modifiedSeed = seed?.replace(/[^A-Z0-9]/gi, "") ?? "default";
  return `https://api.dicebear.com/5.x/${type}/svg?seed=${modifiedSeed}&backgroundColor=b6e3f4`;
}
