import { NotionTaskStatus } from "@grape-law-firm/grape-common-notion-client";
import type { NotionTaskPriority } from "@grape-law-firm/grape-common-notion-client";
import type { GTask } from "~/types/task";

export const useTaskStore = defineStore("task", () => {
  const { $sentry } = useNuxtApp();

  const tasks = ref<GTask[]>();
  const pending = ref(false);

  async function fetchTasks() {
    const notionUserId = useUserStore().info?.notionUserId;
    if (!notionUserId) {
      $sentry.captureError("Notion user id not found", { extra: {
        user: useUserStore().info,
        missive: useMissiveStore().user,
      } });

      // We only throw if this is the initial fetch
      if (!tasks.value)
        throw createError("Notion user id not found");
    }

    pending.value = true;

    try {
      tasks.value = await $fetch<GTask[]>("/api/tasks/getByEmployee", {
        method: "POST",
        body: {
          notionUserId,
        },
        retry: 3,
      });

      void checkForAssignedTasks();
    }
    catch (error: any) {
      $sentry.captureError(error);

      // We only throw if this is the initial fetch
      if (!tasks.value)
        throw createError(`Failed to fetch tasks. ${error.message}`);
    }

    pending.value = false;
  }

  async function checkForAssignedTasks() {
    const ownNotionId = useUserStore().info?.notionUserId;
    const tasksAssignedByOthers = tasks.value?.filter(task => task.createdBy?.notionUserId !== ownNotionId);

    const toast = useToast();
    tasksAssignedByOthers?.forEach((task) => {
      // If user is not notified of this task
      if (!task.notifiedOwners?.some(f => f.notionUserId === ownNotionId)) {
        toast.add({
          title: `New task from ${task.createdBy?.name}`,
          description: `${task.name} (${task.doDate || task.dueDate || "No Due Date"})`,
          timeout: 0,
        });
        void $fetch("/api/tasks/markNotified", {
          method: "POST",
          body: {
            taskId: task.id,
            userIds: [ownNotionId, ...(task.notifiedOwners ?? []).map(f => f.notionUserId)],
          },
        });
      }
    });
  }

  async function getDescription(task: GTask) {
    const description = await $fetch("/api/tasks/getTaskDescription", {
      method: "POST",
      body: {
        notionPageId: task.id,
      },
    });

    return description;
  }

  async function addTask(task: GTask) {
    return await $fetch("/api/tasks/add", {
      method: "POST",
      body: {
        task,
      },
    });
  }

  async function changeStatus(task: GTask, done: boolean) {
    return await $fetch("/api/tasks/changeStatus", {
      method: "POST",
      body: {
        notionPageId: task.id,
        status: done ? NotionTaskStatus.Done : NotionTaskStatus.ToDo,
      },
    });
  }

  async function changeDoDate(task: GTask, newDoDate?: Date) {
    return await $fetch("/api/tasks/changeDoDate", {
      method: "POST",
      body: {
        notionPageId: task.id,
        date: newDoDate,
      },
    });
  }

  async function changePriority(task: GTask, newPriority: NotionTaskPriority) {
    return await $fetch("/api/tasks/changePriority", {
      method: "POST",
      body: {
        notionPageId: task.id,
        priority: newPriority,
      },
    });
  }

  async function deleteTask(task: GTask) {
    return await $fetch("/api/tasks/delete", {
      method: "POST",
      body: {
        notionPageId: task.id,
      },
    });
  }

  return {
    tasks,
    pending,

    fetchTasks,
    getDescription,
    addTask,
    changeStatus,
    changeDoDate,
    changePriority,
    deleteTask,
  };
});
